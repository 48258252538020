<template>

  <div class="login-component" uk-scrollspy="cls: uk-animation-slide-bottom uk-animation-slow;">
    <h4 class="uk-card-title uk-center uk-text-center uk-margin-remove">
      <span v-if=" ! logoSrc"
              class="uk-logo uk-center uk-text-center">[Company logo]</span>
      <span v-else=" ! logoSrc"
            :style="logoStyle"
            class="uk-logo uk-center uk-text-center"></span>
    </h4>

    <div class="uk-card-body">

      <h3 class="uk-center uk-text-center">
        <slot name="subTitle">{{translatedSubTitle}}</slot>
      </h3>

      <div class="pre-login" v-if="shouldShowPrelogin">
        <slot name="prelogin" :preLoginComplete="{preLoginComplete}">prelogin content</slot>
      </div>

      <div class="form-wrapper" v-if="shouldShowForm">
        <slot name="form">
          <base-form
                  :action="formAction"
                  method="post"
                  :formPath="formSource"
                  :expectedFields="2"
                  v-model="loginData"
                  :config="{formHandling}"
                  :generalErrorMessage="'user.loginFailedGenetic'"
                  @form:submitSuccess="loginSuccessHandler"

          >
            <template #afterButtons>
              <div class="forgot-password-wrapper uk-margin-small-top">
                <a href="#" class="uk-link-muted " ><span>Forgot Your Password?</span></a>
              </div>

            </template>
          </base-form>

        </slot>

        <slot name="afterForm"></slot>

      </div>

      <div class="login-footer-links">
        <slot name="footerLinks">
          <div class="uk-flex uk-flex-between">
            <div class="link-wrap">
              <router-link to="/" class="uk-text-meta">Forgot password?</router-link>
            </div>
            <div class="link-wrap">
              <router-link to="/" class="uk-text-meta">Back home</router-link>
            </div>
          </div>

        </slot>

      </div>


    </div>

    <spinner
            overlay="fixed"
            v-if="isFetchingProfile"
            :text="safeTranslate('user.loginInProgress')"
    ></spinner>
  </div>

</template>

<script>
  // todo: default texts, props etc

    export default {
        props: {
          /**
           * Where to redirect after login
           **/
          loginRedirect: {
            type: [String, Object],
            default: '/'
          },
          subTitle: {
            type: String,
            default: 'core.user.loginSubTitle',
          },
          /**
           * Relative path for the logo
           * If false, will use placeholder
           **/
          logoSrc: {
            type: [String, Boolean],
            default: '/'
          },
          /**
           * Api form source (url to get the form)
           **/
          formSource: {
            type: String,
            default: 'form/user-login'
          },
          /**
           * Where does the login form post to. relative API path
           **/
          formAction: {
            type: String,
            default: '/token/refresh/userPassword'
          },
          showPrelogin : {
            type: [Boolean],
            default: true,
          },
          showForm: {
            type: [Boolean],
            default: true,
          }
        },
        data: function () {
            return {
              preLoginComplete: false,
              loginData: {},
              formContext: 'context2',
              formHandling: {
                /*
                  preflight: [
                    {
                      type: 'validation',
                      options: {}
                    },
                    {
                      type: 'server',
                      options: {
                        url : 'test',
                        additionalData : {
                          'some': 'key'
                        },
                        onStart : (form) => {

                        },
                        onEnd : (form, result) => {
                        //  if (result.isError) {
                         //   form.generalErrorMessageInternal = 'test error message';
                        //    form.showSubmitError = true;
                        //  }
                        }
                      }
                    },
                    {
                      type: 'confirmation',
                      options: {
                      //  title: 'configured title',
                     //   content: 'configured content',
                    //    labels: {
                     //     cancelButton: 'CANCEL-',
                      //    okButton: 'OK-'
                     //   }
                      }
                    },
                  ],
                //   requestAdapter: 'default',
                //   responseAdapter: 'default'
               //    selfHandling : false,
                   loadingBehaviour : {
                     'overlay': true,
                     'text': 'core.form.loading'
                   },
                //   successBehaviour : 'default', //todo
                //   errorBehaviour : 'default',
                */
              }
            };
        },
        methods: {
          ourFormCallback () {
            console.log('form callback');
          },
          testMethod (test) {
              console.log('test method!',test);
          },
          async loginSuccessHandler(eventData) {

            this.$store.commit('user/setToken', eventData.resultData.jwt);
            this.$store.commit('user/setRefreshToken', eventData.resultData.refreshToken);

            this.$store.commit('user/setRefreshTokenExpires', eventData.resultData.refreshTokenExpires);

            let result = await this.$store.dispatch('user/updateUserProfile');

            if (result === false) {
                this.$saffron.ui.notification(this.safeTranslate('user.fetchProfileException'), 'danger');
                return false;
            }

            this.$router.push(this.loginRedirect);
          }
      },
        computed : {
          isFetchingProfile () {
            return this.$store.getters['user/fetchingProfile'];
          },
          logoStyle () {
            if ( ! this.logoSrc) {
              return [];
            }

            let url = utilities.requireAsset(this.logoSrc);
            return {
              'backgroundImage' : "url('"+url+"')",
              'height': '80px',
              'width': '80px',
            };
          },
          shouldShowPrelogin() {
            return this.showPrelogin && ! this.preLoginComplete;
        },
          shouldShowForm() {
            return this.showForm || this.preLoginComplete;
          },
          translatedSubTitle() {
            return this.safeTranslate(this.subTitle);
          }
      },

      created () {

      },
      watch: {
          preLoginComplete (newVal, oldVal) {
            console.log('watch', newVal);
          }
      }

    }
</script>

<style scoped lang="scss">

  // TODO: mixin imports, especially directional
  /*

  $breakpoint-small: 640px !default;
$breakpoint-medium: 960px !default;
$breakpoint-large: 1200px !default;
$breakpoint-xlarge: 1600px !default;
   */


  .login-component {
    max-width: calc(90vw - 60px);
    width: 550px;
    flex: 550px;

    @media screen and (max-width: 640px) {
      max-width: calc(90vw - 60px);
      width: 80vw;
      flex: 80vw;
    }

    @media screen and (max-width: 400px) {
      max-width: calc(90vw - 20px);
      width: 90vw;
      flex: 90vw;
    }

    .uk-card-body {
      padding-top: 0;
      padding-bottom: 0;
      @media screen and (max-width: 400px) {
        padding: 20px 10px;
      }
    }

    .uk-logo {
      display: inline-block;
    }

    .login-footer-links {
      @media screen and (max-width: 640px) {
        flex-wrap: wrap;
      }

      .link-wrap {
        @media screen and (max-width: 640px) {

          flex: 100%;
        }
      }
    }
  }
</style>
